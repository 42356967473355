<template>
  <video
    v-if="video"
    playsinline
    :autoplay="autoplay"
    :controls="controls"
    :src="video.Domain + video.Path"
    controlsList="nodownload"
    oncontextmenu="return false;"
    preload="metadata"
    ref="videoElement"
    v-on-key-press="toggleKeyPress"
    @loadedmetadata="onLoadedData"
    @mousedown="() => videoClick(false)"
    @mouseup="() => videoClick(true)"
    @touchend="() => videoClick(true)"
    @touchstart="() => videoClick(false)"
  />
</template>

<script setup lang="ts">
import type { ImageInfo } from '~types/common'

import { useWindowSize, vOnKeyPress } from '@arora/common'

const { loaded = (_durationSeconds: number) => {} } = defineProps<{
  autoplay?: boolean
  controls?: boolean
  video: ImageInfo | null | undefined
  loaded?: (durationSeconds: number) => void
}>()

const { isSmall } = useWindowSize()

const videoElement = ref<HTMLVideoElement>()

let playing = true
function toggleKeyPress(event: KeyboardEvent): void {
  if (event.code === 'Space') {
    playing = !playing
    togglePlay(playing)
  }
}

function onLoadedData(event: Event): void {
  const target = event.target as MediaSource
  loaded(target.duration)
}

function videoClick(play: boolean): void {
  if (isSmall.value) {
    togglePlay(play)
  } else if (!play) {
    playing = !playing
    togglePlay(playing)
  }
}

function togglePlay(play: boolean): void {
  if (play) {
    videoElement.value?.play()
  } else {
    videoElement.value?.pause()
  }
}
</script>
